export const TIERS = ["S", "A", "B", "C", "D", "E", "F"];

export const ITEM_TYPES = {
  CARD: "CARD",
};

export const CARD_TYPES = {
  HERO: "hero",
  TROOP: "troop",
};

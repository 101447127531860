const initialState = {
  grades: [
    { id: "XyDE", title: "Common", order: 4 },
    { id: "3XE9", title: "Rare", order: 3 },
    { id: "fod3", title: "Epic", order: 2 },
    { id: "BB4h", title: "Legendary", order: 1 },
  ],
  races: [
    { id: "HVSp", title: "Human" },
    { id: "u2TP", title: "Wild" },
    { id: "z18Y", title: "Sacred" },
    { id: "T1WD", title: "Dark" },
  ],
  classes: [
    { id: "dRmi", title: "Support" },
    { id: "8ZOV", title: "Mage" },
    { id: "Mcn2", title: "Warrior" },
    { id: "kroW", title: "Assassin" },
    { id: "Hei3", title: "Marksman" },
    { id: "PA95", title: "Tank" },
  ],
  troops: [
    {
      grade: "fod3",
      id: "5Lj1",
      image: "/img/aow-troops/pirateship.png",
      boardImage: "/img/aow-troops-igv/pirateship.png",
      name: "Pirate Ship",
      race: "HVSp",
      count: 1,
    },
    {
      grade: "fod3",
      id: "aPHf",
      image: "/img/aow-troops/nun.png",
      boardImage: "/img/aow-troops-igv/nun.png",
      name: "Nun",
      race: "HVSp",
      count: 9,
    },
    {
      grade: "XyDE",
      id: "1DG4",
      image: "/img/aow-troops/archers.png",
      boardImage: "/img/aow-troops-igv/archers.png",
      name: "Archers",
      race: "HVSp",
      count: 9,
    },
    {
      grade: "XyDE",
      id: "KMfr",
      image: "/img/aow-troops/infantry.png",
      boardImage: "/img/aow-troops-igv/infantry.png",
      name: "Infantry",
      race: "HVSp",
      count: 9,
    },
    {
      grade: "XyDE",
      id: "ia3F",
      image: "/img/aow-troops/ironguards.png",
      boardImage: "/img/aow-troops-igv/ironguards.png",
      name: "Iron Guards",
      race: "HVSp",
      count: 9,
    },
    {
      grade: "XyDE",
      id: "aHk2",
      image: "/img/aow-troops/bomber.png",
      boardImage: "/img/aow-troops-igv/bomber.png",
      name: "Bomber",
      race: "HVSp",
      count: 1,
    },
    {
      grade: "XyDE",
      id: "sYMV",
      image: "/img/aow-troops/catapult.png",
      boardImage: "/img/aow-troops-igv/catapult.png",
      name: "Catapult",
      race: "HVSp",
      count: 1,
    },
    {
      grade: "XyDE",
      id: "duED",
      image: "/img/aow-troops/helljailers.png",
      boardImage: "/img/aow-troops-igv/helljailers.png",
      name: "Hell Jailers",
      race: "T1WD",
      count: 9,
    },
    {
      grade: "3XE9",
      id: "xKS4",
      image: "/img/aow-troops/firemage.png",
      boardImage: "/img/aow-troops-igv/firemage.png",
      name: "Fire Mage",
      race: "HVSp",
      count: 1,
    },
    {
      grade: "3XE9",
      id: "x68d",
      image: "/img/aow-troops/bandits.png",
      boardImage: "/img/aow-troops-igv/bandits.png",
      name: "Bandits",
      race: "HVSp",
      count: 9,
    },
    {
      grade: "3XE9",
      id: "TifV",
      image: "/img/aow-troops/ogrewarrior.png",
      boardImage: "/img/aow-troops-igv/ogrewarrior.png",
      name: "Ogre Warrior",
      race: "u2TP",
      count: 1,
    },
    {
      grade: "3XE9",
      id: "u3vo",
      image: "/img/aow-troops/ghostassassins.png",
      boardImage: "/img/aow-troops-igv/ghostassassins.png",
      name: "Ghost Assassin",
      race: "T1WD",
      count: 9,
    },
    {
      grade: "3XE9",
      id: "VYNk",
      image: "/img/aow-troops/magicapprentice.png",
      boardImage: "/img/aow-troops-igv/magicapprentice.png",
      name: "Magic Apprentice",
      race: "HVSp",
      count: 9,
    },
    {
      grade: "3XE9",
      id: "jE0t",
      image: "/img/aow-troops/vikingwarrior.png",
      boardImage: "/img/aow-troops-igv/vikingwarrior.png",
      name: "Viking Warrior",
      race: "HVSp",
      count: 1,
    },
    {
      grade: "3XE9",
      id: "RwaZ",
      image: "/img/aow-troops/icemage.png",
      boardImage: "/img/aow-troops-igv/icemage.png",
      name: "Ice Mage",
      race: "HVSp",
      count: 1,
    },
    {
      grade: "3XE9",
      id: "X5LF",
      image: "/img/aow-troops/scholar.png",
      boardImage: "/img/aow-troops-igv/scholar.png",
      name: "Scholar",
      race: "z18Y",
      count: 1,
    },
    {
      grade: "3XE9",
      id: "KSiE",
      image: "/img/aow-troops/inquisitor.png",
      boardImage: "/img/aow-troops-igv/inquisitor.png",
      name: "Inquisitor",
      race: "z18Y",
      count: 9,
    },
    {
      grade: "3XE9",
      id: "ELzN",
      image: "/img/aow-troops/undeadsoldier.png",
      boardImage: "/img/aow-troops-igv/undeadsoldier.png",
      name: "Undead Soldier",
      race: "T1WD",
      count: 9,
    },
    {
      grade: "3XE9",
      gradedAs: "fod3",
      id: "YVcP",
      image: "/img/aow-troops/harbingeroffire.png",
      boardImage: "/img/aow-troops-igv/harbingeroffire.png",
      name: "Harbinger of Fire",
      race: "z18Y",
      count: 9,
    },
    {
      grade: "3XE9",
      id: "od7I",
      image: "/img/aow-troops/paladin.png",
      boardImage: "/img/aow-troops-igv/paladin.png",
      name: "Paladin",
      race: "z18Y",
      count: 9,
    },
    {
      grade: "3XE9",
      id: "JmMK",
      image: "/img/aow-troops/ballista.png",
      boardImage: "/img/aow-troops-igv/ballista.png",
      name: "Ballista",
      race: "HVSp",
      count: 1,
    },
    {
      grade: "3XE9",
      id: "qap2",
      image: "/img/aow-troops/goblikazes.png",
      boardImage: "/img/aow-troops-igv/goblikazes.png",
      name: "Goblikazes",
      race: "u2TP",
      count: 9,
    },
    {
      grade: "3XE9",
      id: "cHYq",
      image: "/img/aow-troops/cactus.png",
      boardImage: "/img/aow-troops-igv/cactus.png",
      name: "Cactuses",
      race: "u2TP",
      count: 9,
    },
    {
      grade: "fod3",
      id: "VOFU",
      image: "/img/aow-troops/necromancer.png",
      boardImage: "/img/aow-troops-igv/necromancer.png",
      name: "Necromancer",
      race: "T1WD",
      count: 1,
    },
    {
      grade: "fod3",
      id: "cMlE",
      image: "/img/aow-troops/pilgrims.png",
      boardImage: "/img/aow-troops-igv/pilgrims.png",
      name: "Pilgrims",
      race: "z18Y",
      count: 9,
    },
    {
      grade: "fod3",
      id: "uvZH",
      image: "/img/aow-troops/pumpkinguard.png",
      boardImage: "/img/aow-troops-igv/pumpkinguard.png",
      name: "Pumpkin Guard",
      race: "T1WD",
      count: 1,
    },
    {
      grade: "fod3",
      id: "drTp",
      image: "/img/aow-troops/darkwitch.png",
      boardImage: "/img/aow-troops-igv/darkwitch.png",
      name: "Dark Witch",
      race: "T1WD",
      count: 1,
    },
    {
      grade: "fod3",
      id: "qZFw",
      image: "/img/aow-troops/yasha.png",
      boardImage: "/img/aow-troops-igv/yasha.png",
      name: "Yasha",
      race: "T1WD",
      count: 1,
    },
    {
      grade: "fod3",
      id: "bIYJ",
      image: "/img/aow-troops/priestmage.png",
      boardImage: "/img/aow-troops-igv/priestmage.png",
      name: "Priest Mage",
      race: "z18Y",
      count: 1,
    },
    {
      grade: "fod3",
      id: "Xbyh",
      image: "/img/aow-troops/soulhunter.png",
      boardImage: "/img/aow-troops-igv/soulhunter.png",
      name: "Soul Hunter",
      race: "T1WD",
      count: 1,
    },
    {
      grade: "fod3",
      id: "ZMFE",
      image: "/img/aow-troops/templarknight.png",
      boardImage: "/img/aow-troops-igv/templarknight.png",
      name: "Templar Knight",
      race: "z18Y",
      count: 1,
    },
    {
      grade: "fod3",
      id: "yivK",
      image: "/img/aow-troops/peltasts.png",
      boardImage: "/img/aow-troops-igv/peltasts.png",
      name: "Peltasts",
      race: "HVSp",
      count: 9,
    },
    {
      grade: "fod3",
      id: "uuZv",
      image: "/img/aow-troops/brawlers.png",
      boardImage: "/img/aow-troops-igv/brawlers.png",
      name: "Brawlers",
      race: "HVSp",
      count: 9,
    },
    {
      grade: "fod3",
      id: "EwWV",
      image: "/img/aow-troops/tauruswitcher.png",
      boardImage: "/img/aow-troops-igv/tauruswitcher.png",
      name: "Taurus Witcher",
      race: "u2TP",
      count: 9,
    },
    {
      grade: "fod3",
      id: "OL9j",
      image: "/img/aow-troops/voodoodolls.png",
      boardImage: "/img/aow-troops-igv/voodoodolls.png",
      name: "Voodoo Dolls",
      race: "T1WD",
      count: 9,
    },
    {
      grade: "BB4h",
      id: "hBpl",
      image: "/img/aow-troops/demon.png",
      boardImage: "/img/aow-troops-igv/demon.png",
      name: "Demon",
      race: "T1WD",
      count: 1,
    },
    {
      grade: "BB4h",
      id: "TK2L",
      image: "/img/aow-troops/beastmaster.png",
      boardImage: "/img/aow-troops-igv/beastmaster.png",
      name: "Beast Master",
      race: "z18Y",
      count: 1,
    },
    {
      grade: "BB4h",
      id: "7FVE",
      image: "/img/aow-troops/witchcrafttotem.png",
      boardImage: "/img/aow-troops-igv/witchcrafttotem.png",
      name: "Witchcraft Totem",
      race: "u2TP",
      count: 1,
    },
    {
      grade: "BB4h",
      id: "BMEK",
      image: "/img/aow-troops/meteorgolem.png",
      boardImage: "/img/aow-troops-igv/meteorgolem.png",
      name: "Meteor Golem",
      race: "T1WD",
      count: 1,
    },
    {
      grade: "BB4h",
      id: "BHYu",
      image: "/img/aow-troops/stonegolem.png",
      boardImage: "/img/aow-troops-igv/stonegolem.png",
      name: "Stone Golem",
      race: "u2TP",
      count: 1,
    },
    {
      grade: "BB4h",
      id: "GOiK",
      image: "/img/aow-troops/frostarchers.png",
      boardImage: "/img/aow-troops-igv/frostarchers.png",
      name: "Frost Archers",
      race: "z18Y",
      count: 9,
    },
    {
      grade: "BB4h",
      id: "zX4n",
      image: "/img/aow-troops/sacredswordsman.png",
      boardImage: "/img/aow-troops-igv/sacredswordsman.png",
      name: "Sacred Swordsman",
      race: "z18Y",
      count: 1,
    },
    {
      grade: "BB4h",
      id: "MIgO",
      image: "/img/aow-troops/rhinoknight.png",
      boardImage: "/img/aow-troops-igv/rhinoknight.png",
      name: "Rhino Knight",
      race: "u2TP",
      count: 1,
    },
    {
      grade: "BB4h",
      id: "IKOP",
      image: "/img/aow-troops/pharaoh.png",
      boardImage: "/img/aow-troops-igv/pharaoh.png",
      name: "Pharaoh",
      race: "HVSp",
      count: 1,
    },
  ],
  heroes: [
    {
      id: "Xfwz",
      name: "Mad Alchemist - Hohenheim",
      grade: "3XE9",
      race: "HVSp",
      class: "dRmi",
      image: "/img/aow-heroes/hohenheim.png",
    },
    {
      id: "YI3s",
      name: "Engineer - Planck",
      grade: "3XE9",
      race: "HVSp",
      class: "8ZOV",
      image: "/img/aow-heroes/planck.png",
    },
    {
      id: "i1hS",
      name: "Minstrel - Dante",
      grade: "3XE9",
      race: "HVSp",
      class: "8ZOV",
      image: "/img/aow-heroes/dante.png",
    },
    {
      id: "DZGh",
      name: "Tree of Life - Green",
      grade: "3XE9",
      race: "u2TP",
      class: "dRmi",
      image: "/img/aow-heroes/green.png",
    },
    {
      id: "WiY9",
      name: "Jungle Hunter - Diana",
      grade: "3XE9",
      race: "HVSp",
      class: "kroW",
      image: "/img/aow-heroes/diana.png",
    },
    {
      id: "j1ym",
      name: "Frost Baron - Kelvins",
      grade: "fod3",
      race: "T1WD",
      class: "Mcn2",
      image: "/img/aow-heroes/kelvins.png",
    },
    {
      id: "OupS",
      name: "Elemental Sorcerer - Illum",
      grade: "fod3",
      race: "HVSp",
      class: "8ZOV",
      image: "/img/aow-heroes/illum.png",
    },
    {
      id: "fvns",
      name: "Archon - Moshe",
      grade: "fod3",
      race: "z18Y",
      class: "8ZOV",
      image: "/img/aow-heroes/moshe.png",
    },
    {
      id: "9bv9",
      name: "Black Beard - Edward",
      grade: "fod3",
      race: "HVSp",
      class: "dRmi",
      image: "/img/aow-heroes/edward.png",
    },
    {
      id: "nCuH",
      name: "Christmas Guardian - Kriss",
      grade: "fod3",
      race: "z18Y",
      class: "Mcn2",
      image: "/img/aow-heroes/kriss.png",
    },
    {
      id: "LHtK",
      name: "Winter Lord - Ainz",
      grade: "fod3",
      race: "T1WD",
      class: "Mcn2",
      image: "/img/aow-heroes/ainz.png",
    },
    {
      id: "5OcS",
      name: "Prince of Thieves - Robin Hood",
      grade: "fod3",
      race: "HVSp",
      class: "Hei3",
      image: "/img/aow-heroes/robinhood.png",
    },
    {
      id: "OQli",
      name: "Bull Demon King - Minotaur",
      grade: "fod3",
      race: "u2TP",
      class: "Mcn2",
      image: "/img/aow-heroes/minotaur.png",
    },
    {
      id: "Zhfz",
      name: "Tarot Master - Cassandra",
      grade: "fod3",
      race: "T1WD",
      class: "8ZOV",
      image: "/img/aow-heroes/cassandra.png",
    },
    {
      id: "CbHd",
      name: "Bloody Warewolf - Miller",
      grade: "fod3",
      race: "u2TP",
      class: "kroW",
      image: "/img/aow-heroes/miller.png",
    },
    {
      id: "GAcg",
      name: "Sakura Blade - Genichiro",
      grade: "fod3",
      race: "T1WD",
      class: "Mcn2",
      image: "/img/aow-heroes/genichiro.png",
    },
    {
      id: "ArWp",
      name: "Admiral of the New Route - Drake",
      grade: "BB4h",
      race: "HVSp",
      class: "Mcn2",
      image: "/img/aow-heroes/drake.png",
    },
    {
      id: "ARHK",
      name: "Thunder King - Ivan",
      grade: "BB4h",
      race: "HVSp",
      class: "Mcn2",
      image: "/img/aow-heroes/ivan.png",
    },
    {
      id: "2ZWr",
      name: "Clockman - Harrison",
      grade: "BB4h",
      race: "HVSp",
      class: "dRmi",
      image: "/img/aow-heroes/harrison.png",
    },
    {
      id: "cBFI",
      name: "Shining Aureole - Apollo",
      grade: "BB4h",
      race: "z18Y",
      class: "Hei3",
      image: "/img/aow-heroes/apollo.png",
    },
    {
      id: "v9oW",
      name: "Guardian of Emerald Ocean - Poseidon",
      grade: "BB4h",
      race: "z18Y",
      class: "8ZOV",
      image: "/img/aow-heroes/poseidon.png",
    },
    {
      id: "DUBg",
      name: "Moon Goddess - Selene",
      grade: "BB4h",
      race: "z18Y",
      class: "dRmi",
      image: "/img/aow-heroes/selene.png",
    },
    {
      id: "qe78",
      name: "The Monkey King - Wukong",
      grade: "BB4h",
      race: "u2TP",
      class: "Mcn2",
      image: "/img/aow-heroes/wukong.png",
    },
    {
      id: "tPKl",
      name: "Burning Council - Davison",
      grade: "BB4h",
      race: "HVSp",
      class: "PA95",
      image: "/img/aow-heroes/davison.png",
    },
    {
      id: "4ZBz",
      name: "Scarlet Duke - Dracula",
      grade: "BB4h",
      race: "T1WD",
      class: "8ZOV",
      image: "/img/aow-heroes/dracula.png",
    },
    {
      id: "SaVx",
      name: "Royal Christmas Ball - Chione",
      grade: "BB4h",
      race: "z18Y",
      class: "8ZOV",
      image: "/img/aow-heroes/chione.png",
    },
    {
      id: "yW9G",
      name: "Lord of Hatred - Mephisto",
      grade: "BB4h",
      race: "T1WD",
      class: "Mcn2",
      image: "/img/aow-heroes/mephisto.png",
    },
    {
      id: "7FYw",
      name: "Horus the Elder - Horus",
      grade: "BB4h",
      race: "z18Y",
      class: "8ZOV",
      image: "/img/aow-heroes/horus.png",
    },
    {
      id: "OJ6m",
      name: "The King of Camelot - Arthur",
      grade: "BB4h",
      race: "HVSp",
      class: "Mcn2",
      image: "/img/aow-heroes/arthur.png",
    },
    {
      id: "NUMk",
      name: "Intrepid King - Beowulf",
      grade: "BB4h",
      race: "HVSp",
      class: "Mcn2",
      image: "/img/aow-heroes/beowulf.png",
    },
    {
      id: "MXhs",
      name: "The Nine-Tailed Fox - Aly",
      grade: "BB4h",
      race: "u2TP",
      class: "8ZOV",
      image: "/img/aow-heroes/aly.png",
    },
    {
      id: "U5Bu",
      name: "Tentacles of the Deep - Kraken",
      grade: "BB4h",
      race: "u2TP",
      class: "PA95",
      image: "/img/aow-heroes/kraken.png",
    },
    {
      id: "MVQQ",
      name: "Grim Reaper - Azrael",
      grade: "BB4h",
      race: "T1WD",
      class: "8ZOV",
      image: "/img/aow-heroes/azrael.png",
    },
    {
      id: "uLhj",
      name: "King of the Gods - Zeus",
      grade: "BB4h",
      race: "z18Y",
      class: "8ZOV",
      image: "/img/aow-heroes/zeus.png",
    },
    {
      id: "9VqF",
      name: "Burning Spear - Nezha",
      grade: "BB4h",
      race: "z18Y",
      class: "Mcn2",
      image: "/img/aow-heroes/nezha.png",
    },
    {
      id: "3PGT",
      name: "Goddess of War - Athena",
      grade: "BB4h",
      race: "z18Y",
      class: "Mcn2",
      image: "/img/aow-heroes/athena.png",
    },
    {
      id: "d6Wc",
      name: "Father of Gods - Odin",
      grade: "BB4h",
      race: "z18Y",
      class: "8ZOV",
      image: "/img/aow-heroes/odin.png",
    },
    {
      id: "AgM6",
      name: "The Sprite of Lamp - Jinn",
      grade: "BB4h",
      race: "z18Y",
      class: "dRmi",
      image: "/img/aow-heroes/jinn.png",
    },
    {
      id: "Q5Kj",
      name: "The Queen of Silla - Seondeok",
      grade: "BB4h",
      race: "HVSp",
      class: "dRmi",
      image: "/img/aow-heroes/seondeok.png",
    },
  ],
};

export default initialState;
